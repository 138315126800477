import './App.css';
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LeaderboardDino from './LeaderboardDino';  

const theme = createTheme({
  palette: {
    primary: {
      main: '#1fd0e9',
    },
    background: {
      default: '#04041F',
    },
    textPrimary: {
      main: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<LeaderboardDino verse={false} />} />
          <Route path="/verse" element={<LeaderboardDino verse={true} />} />
          <Route path="/iaapa" element={<LeaderboardDino iaapa={true} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
