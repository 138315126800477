import React, { useState, useEffect } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, Typography, Box, Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import { useMediaQuery, useTheme } from '@mui/material';

const baseURL = 'https://identity-service.enklu.com';

const Container = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  margin: 'auto',
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #04041F 40%, #322669 80%)',
  marginBottom: 50,
  [theme.breakpoints.down('sm')]: { 
    marginBottom: 30,
    padding: 0,
  },
}));

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: '3px solid white',
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '1.2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const UsernameHeaderCell = styled(HeaderCell)(({ theme }) => ({
  textAlign: 'left',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // remove border of TableCell in the last row
  '&:last-child td': {
    border: 0,
  },
}));

const RankStyled = styled(Avatar)(({ theme, index }) => ({
  backgroundColor: 'transparent',
  borderRadius: 0,
  color: theme.palette.common.white,
  fontSize: index > 2 ? '' : '2.6rem',
  margin: 'auto',
  height: 50
}));

const UsernameCell = styled(TableCell)(({ theme }) => ({
  fontSize: '1.6rem',
  color: theme.palette.common.white,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
    paddingRight: 0,
  },
}));

const TableDataCell = styled(TableCell)(({ theme }) => ({
  fontSize: '1.1rem',
  textAlign: 'center',
  color: theme.palette.common.white,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

const TitleButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  background: selected ? 'linear-gradient(45deg, #2c6faf 60%, #45f7ff 90%)' : 'black',
  color: selected ? 'white' : theme.palette.common.white,
  borderRadius: '10px',
  textTransform: 'none',
  fontSize: '1.4rem',
  flex: 1,
  '&:hover': {
    backgroundColor: selected ? '' : '#2c6faf',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
    padding: theme.spacing(0, 1.5),
  },
}));

const StyledButtonGroup = styled(ButtonGroup)({
  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRight: 'none',
  },
  '& .MuiButtonGroup-grouped': {
    minWidth: '80px',
  },
  // set the border color to white for all buttons
  '& .MuiButton-root': {
    borderColor: 'white',
  },
});

const LocationTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  color: theme.palette.common.white,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem',
  },
}));

const Leaderboard = ({ verse, iaapa }) => {
  const [selectedLeaderboard, setSelectedLeaderboard] = useState('top_rating');
  const [data, setData] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // -- Simulating click to prevent the TV from going to screensave mode --
  const simulateClick = () => {
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });

    const element = document.body;
    element.dispatchEvent(event);

    console.log('Simulated click event to prevent screensaver.');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      simulateClick();
    }, 9 * 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const getData = async () => {
      console.log('Fetching data...');
      fetch(baseURL + '/gettopdinos', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }

    // if verse is true, set an interval to fetch data every 30 secs and switch the selected leaderboard
    if (verse) {
      getData();
      const interval = setInterval(() => {
        getData();
        setSelectedLeaderboard((prevSelectedLeaderboard) =>
          prevSelectedLeaderboard === 'top_rating' ? 'top_points' : 'top_rating'
        );
      }, 30000);
      return () => clearInterval(interval);
    } else if (iaapa) {
      // Get data from dummyData.json for iaapa
      const dummyData = fetch('/dummyData.json').then((response) => response.json());
      dummyData.then((data) => {
        setData(data);
      });
      // Keep switching the selected leaderboard every 30 secs
      const interval = setInterval(() => {
        setSelectedLeaderboard((prevSelectedLeaderboard) =>
          prevSelectedLeaderboard === 'top_rating' ? 'top_points' : 'top_rating'
        );
      }, 30000);
      return () => clearInterval(interval);
    }
    else {
      getData();
    }
  }, [verse, iaapa]);

  const columns = data[selectedLeaderboard] ? Object.keys(data[selectedLeaderboard][0]) : [];

  return (

    <Box sx={{ 
      position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, 
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'white',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#ccc',
      },
    }}>

      <Box sx={{ textAlign: 'center', margin: 2 }}>
        <Typography variant={isSmallScreen ? "h4" : "h3"} color={'white'} fontWeight={'bold'}>Primal Ranch Leaderboard</Typography>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={2} sx={{ maxWidth: 450, mx: 'auto', px: 1 }}>
        <StyledButtonGroup variant="outlined">
          <TitleButton onClick={() => setSelectedLeaderboard('top_rating')} selected={selectedLeaderboard === 'top_rating'}>
            Dino
          </TitleButton>
          <TitleButton onClick={() => setSelectedLeaderboard('top_points')} selected={selectedLeaderboard === 'top_points'}>
            Player
          </TitleButton>
        </StyledButtonGroup>
      </Box>


      <Container>
        <TableContainer>
          <Table>
            <TableHead>
              <StyledHeaderRow>
                { !isSmallScreen &&
                  <HeaderCell>Rank</HeaderCell> 
                }
                <UsernameHeaderCell>Username</UsernameHeaderCell>
                {columns.map((column, index) => (
                  column !== 'Username' && (column !== 'Location' || !isSmallScreen) && (column !== 'Dino Level' || !isSmallScreen) && (column !== 'Dino Name' || !isSmallScreen) && 
                  <HeaderCell key={index}>
                    {selectedLeaderboard === 'top_dinos' && column === 'Dino Level' ? '\u{1F3C6}' : ''}
                    {selectedLeaderboard === 'top_points' && column === 'Points' ? '\u{1F3C6}' : ''}
                    {selectedLeaderboard === 'top_rating' && column === 'Rating' ? '\u{1F3C6}' : ''}
                    {column.charAt(0).toUpperCase() + column.slice(1)}
                    {selectedLeaderboard === 'top_dinos' && column === 'Dino Level' ? '\u{1F3C6}' : ''}
                    {selectedLeaderboard === 'top_points' && column === 'Points' ? '\u{1F3C6}' : ''}
                    {selectedLeaderboard === 'top_rating' && column === 'Rating' ? '\u{1F3C6}' : ''}
                  </HeaderCell>
                ))}
              </StyledHeaderRow>
            </TableHead>
            <TableBody>
              {data.length !== 0 &&
                data[selectedLeaderboard].map((row, index) => (
                  <StyledTableRow key={index}>
                    { !isSmallScreen &&
                      <TableCell>
                        <RankStyled index={index}>
                          {
                            // Show medal emoji for top 3 ranks
                            index === 0 ? '\u{1F947}' :
                              index === 1 ? '\u{1F948}' :
                                index === 2 ? '\u{1F949}' :
                                  index + 1
                              
                          }
                        </RankStyled>
                      </TableCell>
                    }
                    <UsernameCell>
                      <Typography sx={{fontWeight: "bold", fontSize: 'inherit'}} >{row.Username}</Typography>
                      { isSmallScreen &&
                        <LocationTypography>
                          {selectedLeaderboard==="top_rating" ? `${row['Dino Name'].split(',')[0]} | Level ${row['Dino Level']} |` : ""}  {row.Location}
                        </LocationTypography>
                      } 
                    </UsernameCell>
                    {columns.map((column, index) => (
                      (column !== 'Username') && (column !== 'Location' || !isSmallScreen) && (column !== 'Dino Level' || !isSmallScreen) && (column !== 'Dino Name' || !isSmallScreen) && 
                      <TableDataCell key={index}
                        sx={
                          (selectedLeaderboard === 'top_dinos' && column === 'Dino Level') ||
                          (selectedLeaderboard === 'top_points' && column === 'Points') ||
                          (selectedLeaderboard === 'top_rating' && column === 'Rating') ?
                            { fontSize: { xs: '1.2rem', sm: '1.4rem' } } : {}
                        }
                      >
                        { column === 'Dino Name' ? (
                          <Box>
                            {row[column].split(',')[0]}
                            {!isSmallScreen && <Typography sx={{fontSize: '0.8rem'}}>{row[column].split(',')[1]}</Typography> }
                          </Box>
                        )
                           : 
                          row[column] }
                      </TableDataCell>
                    ))}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

    </Box>
  );
};

export default Leaderboard;
